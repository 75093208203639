export const homeRoute = "/";
export const aboutRoute = "/about";
export const authRoute = "/auth";
export const pwdRoute = "/forgot";
export const signupRoute = "/signup";

// Private routes
export const providerRoute = "/provider";
export const formRoute = "/provider/add";
export const templateRoute = "/provider/template";
export const chatRoute = "/provider/feedback";
export const settingsRoute = "/provider/settings";
